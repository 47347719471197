// src/Counter.js
import axios from "axios";
import { log } from "console";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "react-modal";

interface FormData {
  username: string;
  password: string;
}

function Login() {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [validEmail, setValidEmail] = useState<boolean>(true);
  const [validPassword, setValidPassword] = useState<boolean>(true);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState("Server Error");

  const [usernameSelection, setUsernameSelection] = useState<boolean>(true);
  const [passwordSelection, setPasswordTextSelection] =
    useState<boolean>(false);
  const [passwordIconSelection, setPasswordIconSelection] =
    useState<boolean>(false);
  const [loginButtonSelection, setLoginButtonSelection] =
    useState<boolean>(false);

  const userNameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const [formData, setFormData] = useState<FormData>({
    username: "",
    password: "",
  });

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    // if (userNameRef.current) {
    //   userNameRef.current.focus();
    // }
  }, []);

  useEffect(()=>{
    document.body.style.overflow = "hidden";
  },[])

  useEffect(() => {

    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn) {
      history.push("/Home");
    }
  }, []);
  const handleSubmit = (event: any) => {
    console.log("eventeventevent", formData);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValidEmail = emailRegex.test(formData.username);

    setValidEmail(isValidEmail);
    if (formData.password) {
      setValidPassword(true);
    } else {
      setValidPassword(false);
    }
    if (isValidEmail && formData.password) {
      setLoader(true);
      // const postData = {
      //   email: formData.username,
      //   password: formData.password,
      // };
      axios
        .get(
          `https://api.ambanilearn.com/api/v1/auth/login-v1/${formData.username}/${formData.password}`
        )
        // axios
        //   .post("https://api.ambanilearn.com/api/v1/auth/login", postData)
        .then((response) => {
          console.log("loginresponse", response);

          setLoader(false);
          if (response.data.success) {
            localStorage.setItem("isLoggedIn", "true");
            localStorage.setItem("token", response.data.token);
            history.push("/Home");
          }
        })
        .catch((error) => {
          // console.log("errorerrorerrorerror", error);
          setPopUpVisible(true);
          setErrorAlertMessage(error.response.data.error);
          // alert(error.response.data.error);
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      switch (event.key) {
        case "ArrowUp":
          if (loginButtonSelection) {
            setLoginButtonSelection(false);
            setPasswordTextSelection(true);
            if (passwordRef.current) {
              passwordRef.current.focus();
            }
          } else if (passwordSelection || passwordIconSelection) {
            setPasswordTextSelection(false);
            setPasswordIconSelection(false);
            setUsernameSelection(true);
            if (userNameRef.current) {
              userNameRef.current.focus();
            }
          }
          break;
        case "ArrowDown":
          if (usernameSelection) {
            setUsernameSelection(false);
            setPasswordTextSelection(true);
            if (passwordRef.current) {
              passwordRef.current.focus();
            }
          } else if (passwordSelection || passwordIconSelection) {
            setPasswordTextSelection(false);
            setPasswordIconSelection(false);
            setLoginButtonSelection(true);
            if (passwordRef.current) {
              passwordRef.current.blur();
            }
          }
          break;
        case "ArrowLeft":
          if (passwordIconSelection) {
            setPasswordIconSelection(false);
            setPasswordTextSelection(true);
            if (passwordRef.current) {
              passwordRef.current.focus();
            }
          }
          if (passwordRef.current && passwordIconSelection) {
            passwordRef.current.selectionEnd = 0;
            return;
          }
          break;
        case "ArrowRight":
          if (
            passwordRef?.current?.selectionStart != formData.password.length
          ) {
            return;
          }
          if (passwordSelection) {
            if (passwordRef.current) {
              passwordRef.current.blur();
            }
            setPasswordIconSelection(true);
            setPasswordTextSelection(false);
          }
          break;
        case "Enter":
          if (passwordIconSelection) {
            setShowPassword(!showPassword);
          } else {
            handleSubmit(event);
          }
          break;
        default:
          break;
      }
    };

    const handleClick = (event: any) => {
      console.log("eventevent", event);
      const targetId = event.target.id;
      console.log("targetId", targetId);

      switch (targetId) {
        case "username":
          setUsernameSelection(true);
          setPasswordTextSelection(false);
          setPasswordIconSelection(false);
          if (userNameRef.current) {
            userNameRef.current.focus();
          }
          break;
        case "password":
          setUsernameSelection(false);
          setPasswordTextSelection(true);
          setPasswordIconSelection(false);
          if (passwordRef.current) {
            passwordRef.current.focus();
          }
          break;
        case "loginButton":
          setUsernameSelection(false);
          setPasswordTextSelection(false);
          setPasswordIconSelection(false);
          setLoginButtonSelection(true);
          if (passwordRef.current) {
            passwordRef.current.blur();
          }
          break;
        case "passwordIcon":
          setShowPassword(!showPassword);

          setPasswordIconSelection(true);
          setUsernameSelection(false);
          setPasswordTextSelection(false);
          if (userNameRef.current) {
            userNameRef.current.blur();
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    document.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      document.removeEventListener("click", handleClick);
    };
  }, [
    usernameSelection,
    passwordSelection,
    loginButtonSelection,
    passwordIconSelection,
    userNameRef,
    passwordRef,
    showPassword,
    formData,
  ]);

  useEffect(() => {
    if (passwordRef.current && passwordIconSelection) {
      passwordRef.current.selectionEnd = formData.password.length;
    }
  }, [passwordSelection, passwordIconSelection]);

  useEffect(() => {

    if (popUpVisible) {
      setTimeout(() => {
        setPopUpVisible(false);
        setErrorAlertMessage("Server Error");
      }, 1500);
    }
  }, [popUpVisible]);

  const renderErrorShowModal = () => {
    return (
      <Modal
        ariaHideApp={false}
        onRequestClose={() => setPopUpVisible(false)}
        style={{
          overlay: {
            zIndex: 1000, // Increased zIndex to ensure it's on top
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            margin: 0,
            padding: 0,
            border: "none",
            left: 0,
            top: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
            background: "transparent",
            alignSelf: "flex-start",
          },
        }}
        isOpen={popUpVisible}
      >
        <div
          style={{
            textAlign: "center",
            marginTop: "0.5rem",
            padding:"10px 10px",
            display:"flex",alignItems:'center',
            background: "#FFF",
            height:'1.5rem',
            borderRadius: 8,
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <p style={{ color: "red" }}>{errorAlertMessage}</p>
        </div>
      </Modal>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <img
        alt=""
        style={{
          height: "100vh",
          width: "100%",
          objectFit: "cover",
        }}
        src={require("../src/assets/images/login_bg.png")}
      />
      {!loader ? (
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            alignItems: "center",
            flexDirection: "column",
            display: "flex",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        >
          <div style={{ marginTop: "40px" }}>
            <img
              alt=""
              style={{
                height: "150px",
                objectFit: "cover",
              }}
              src={require("../src/assets/images/ic_ambani_logo.png")}
            />
          </div>
          <div className="bordered-div">
            <div
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                fontStyle: "normal",
                fontFamily: "DMSans-Bold",
                marginTop: "30px",
              }}
            >
              Log into your account
            </div>
            {/* <form onSubmit={handleSubmit}> */}
            <div className="account-form">
              <div style={{ padding: 40 }}>
                <div>
                  {/* usernameSelection */}
                  {/* border: watchSelectionItem ? "1px solid pink" : "0px solid red",
                boxShadow: watchSelectionItem
                  ? "1px 1px 20px 1px red"
                  : "0px 0px 0px 0px pink", */}
                  <div
                    style={{
                      border: usernameSelection
                        ? "0.5px solid pink"
                        : "1px solid #ccc",
                      boxShadow: usernameSelection
                        ? "1px 1px 10px 1px red"
                        : "0px 0px 0px 0px pink",
                      marginBottom: validEmail ? "20px" : "0px",
                    }}
                    className="account-field"
                  >
                    <img
                      style={{
                        marginRight: "20px",
                        objectFit: "cover",
                        width: "28px",
                      }}
                      src={require("../src/assets/images/ic_new_email.png")}
                      alt="Email icon"
                    />
                    <input
                      type="text"
                      autoComplete="off"
                      name="username"
                      ref={userNameRef}
                      value={formData.username}
                      style={{
                        flexGrow: 1,
                        fontFamily: "DMSans-Regular",
                      }}
                      onChange={handleInputChange}
                      placeholder="Enter your email"
                      id="username"
                    />
                  </div>
                  {!validEmail && (
                    <div
                      style={{
                        color: "red",
                        marginBottom: "20px",
                        marginLeft: "8px",
                        marginTop: "5px",
                      }}
                    >
                      Please enter a valid email address.
                    </div>
                  )}
                </div>

                <div
                  style={{
                    border: passwordSelection
                      ? "0.5px solid pink"
                      : "1px solid #ccc",
                    boxShadow: passwordSelection
                      ? "1px 1px 10px 1px red"
                      : "0px 0px 0px 0px #ccc",
                    marginBottom: validPassword ? "20px" : "0px",
                  }}
                  className="account-field"
                >
                  <img
                    style={{
                      marginRight: "20px",
                      objectFit: "cover",
                      width: "28px",
                    }}
                    src={require("../src/assets/images/ic_new_password.png")}
                    alt="Email icon"
                  />
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    autoComplete="off"
                    ref={passwordRef}
                    // onFocus={handleFocus}
                    value={formData.password}
                    style={{ flexGrow: 1, fontFamily: "DMSans-Regular" }}
                    onChange={handleInputChange}
                    placeholder="Enter your password"
                    id="password"
                  />
                  <div
                    style={{
                      width: "10%",
                      alignItems: "center",
                      justifyContent: "end",
                      display: "flex",
                      border: passwordIconSelection
                        ? "0.5px solid pink"
                        : "0px solid #ccc",
                      borderRadius: "10px",
                      boxShadow: passwordIconSelection
                        ? "1px 1px 10px 1px red"
                        : "0px 0px 0px 0px #ccc",
                    }}
                  >
                    <img
                      id="passwordIcon"
                      style={{
                        width: "20px",
                        padding: "5px",
                        cursor: "pointer",
                        objectFit: "cover",
                      }}
                      src={require("../src/assets/images/" +
                        (showPassword ? "show.png" : "hide.png"))}
                      alt={showPassword ? "Show password" : "Hide password"}
                      // onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                </div>
                {!validPassword && (
                  <div
                    style={{
                      color: "red",
                      marginBottom: "20px",
                      marginLeft: "8px",
                      marginTop: "5px",
                    }}
                  >
                    Please enter password.
                  </div>
                )}
                <div
                  id="loginButton"
                  onClick={handleSubmit}
                  style={{
                    cursor: "pointer",
                    border: loginButtonSelection
                      ? "0.5px solid pink"
                      : "1px solid #ccc",
                    boxShadow: loginButtonSelection
                      ? "1px 1px 10px 1px red"
                      : "0px 0px 0px 0px pink",
                    color: "#FFF",
                    // cursor: "pointer",
                    fontFamily: "Inter-Bold",
                    background: "#fe0e78",
                    display: "flex",
                    marginTop: "20%",
                    justifyContent: "center",
                  }}
                  className="account-field"
                >
                  LOGIN
                </div>
              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            display: "flex",
            top: 0,
            left: 0,
          }}
        >
          <ClipLoader
            color={"#fe0e78"}
            loading={true}
            size={80}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      {renderErrorShowModal()}
    </div>
  );
}

export default Login;

import { useEffect, useRef, useState } from "react";
import "../src/tvStyle.css";
import "slick-carousel/slick/slick.css";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import Modal from "react-modal";
import ClipLoader from "react-spinners/ClipLoader";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom";
import { findDOMNode } from "react-dom";
import Read from "./Read";

interface Item {
  categories: [];
  classId: string;
  createdAt: string;
  duration: string;
  favUsers: [];
  hwatch: boolean;
  id: string;
  isDiscovery: boolean;
  language: [];
  name: string;
  photo: string;
  publishByBoclips: [];
  school: [];
  slug: string;
  user: string;
  watchvideo: string;
  __v: number;
  _id: string;
}

const Home = () => {
  const MathsData = [
    {
      url: require("./assets/images/c-1.png"),
      alt: "Image 1",
      name: "MATHS",
      Apiname: "Maths",
    },
    {
      url: require("./assets/images/c-2.png"),

      alt: "Image 2",
      name: "TECHNOLOGY",
      Apiname: "Technology",
    },
    {
      url: require("./assets/images/c-3.png"),

      alt: "Image 3",
      name: "SONGS",
      Apiname: "Songs",
    },
    {
      url: require("./assets/images/c-4.png"),
      alt: "Image 1",
      name: "LIFE SKILLS",
      Apiname: "Life-Skills",
    },
    {
      url: require("./assets/images/c-5.png"),

      alt: "Image 2",
      name: "ENGLISH LITERACY",
      Apiname: "English Literacy",
    },
    {
      url: require("./assets/images/c-6.png"),

      name: "ENVIRONMENT",
      Apiname: "Environment",
    },
    {
      url: require("./assets/images/c-7.png"),
      alt: "Image 1",
      name: "LANGUAGE LEARNING",
      Apiname: "Language",
    },
  ];
  const history = useHistory();
  const [initialSlide, setInitialSlide] = useState(0);
  const [selectedItem, setSelectedItem] = useState("");
  const [listItemData, setListDataItem] = useState("");
  const [scrollX, setScrollX] = useState(0);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [firstDataSelect, setFirstDataSelect] = useState(true);
  const [logOutButton, setlogOutButton] = useState(true);
  const [listManages, setListManage] = useState(true);
  const [firsTimeKeyDown, setfirsTimeKeyDown] = useState(true);
  const [controlShow, setControlShow] = useState(false);
  const [forwardSelection, setForwardSelection] = useState(false);
  const [backwardSelection, setBackwardSelection] = useState(false);
  const [publishByBoclipsVideo, setPublishByBoclips] = useState(false);
  const [watchSelection, setWatchSelection] = useState(true);
  const [watchSelectionItem, setWatchSelectionItem] = useState(false);
  const [manageIfElse, setManageIfElse] = useState(true);
  const [secondManageIfElse, setSecondManageIfElse] = useState(true);
  const [secondLoader, setSecondLoader] = useState(false);
  const [thirdLoader, setThirdLoader] = useState(true);
  const [firstTimeLeftKeyPress, setFirstTimeLeftKeyPress] = useState(true);
  const [footerRow, setFooterRow] = useState(false);
  const [footerItem, setFooterItem] = useState("Home");
  const [tabSelection, setTabSelection] = useState("");
  const [loader, setLoader] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [cardDataIndex, setCradDataIndex] = useState(Number);
  const [cardLeftRightIndex, setCardRightLeftIndex] = useState(0);
  const [dataSave, setDataSave] = useState<Item[]>([]);
  const [remoteData, setRemoteData] = useState<Item[]>([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [arrayLengthStrore, setArrayLengthStore] = useState<Item[]>([]);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const sliderRef = useRef<Slider | null>(null);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn == null) {
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("token");
      history.push("/Login");
    }
  }, []);
  useEffect(() => {
    getListApiCall(MathsData[0]);
    setCardRightLeftIndex(0);
  }, []);

  const getListApiCall = (item: any) => {
    if (categoryName != item.Apiname) {
      setLoader(true);
      const token = localStorage.getItem("token");
      const baseUrl = "https://api.ambanilearn.com/api/v1/hwatch/getVideosForTv?categories="+item.Apiname
      const url = `${baseUrl}&token=Bearer ${token}&page=1&limit=10000`;
      axios
        .get(
          url
        )
        .then((response) => {
          console.log("responseresponseresponse",response);
          
          setLoader(false);
          setCategoryName(item.Apiname);
          setDataSave(response.data.data);
          const newArray: any[] = [];
          for (let i = 0; i < response.data.data.length; i++) {
            newArray.push(((i % 4) + 1) * 4);
            if (newArray.length === 4) break;
          }
          setArrayLengthStore(newArray);
        })
        .catch((error) => {
          setLoader(false);
          console.error("Error fetching data:", error);
        });
    }
  };

  const onClickMainSliderList = (item: any, index: any) => {
    console.log("onClickMainSliderList", index);
    console.log("onClickMainSliderList", cardLeftRightIndex);
    setTabSelection("");
    setManageIfElse(false);
    setListManage(true);
    setWatchSelection(false);
    setWatchSelectionItem(false);
    setSelectedItem(item.name);
    setfirsTimeKeyDown(false);
    getListApiCall(item);
    setCardRightLeftIndex(index);
    sliderRef.current?.slickGoTo(index);
  };

  const onClickImageVideoPlay = (item: any, index: any) => {
    setListManage(false);
    if (index < dataSave.length) {
      if (dataSave.length != 0) {
        setRemoteData(item);
        setListDataItem(item._id);
        setCradDataIndex(index);
        setFirstDataSelect(false);
        setSelectedItem("");
        byBoclipsVideoUrl(item);
        setCurrentTime(0);
        setDuration(0);
      } else {
        setFooterRow(true);
        setFooterItem("Home");
        setListDataItem("");
        setCradDataIndex(-2);
      }
    }
  };

  const FreeDaTa = () => {
    return (
      <div
        style={{
          width: "100%",
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {!loader ? (
          <div>
            {dataSave.length > 0 && (
              <div
                style={{
                  fontSize: "17px",
                  marginBottom: "10px",
                  marginTop: "20px",
                  fontWeight: "600",
                  fontStyle: "normal",
                  fontFamily: "Inter",
                }}
              >
                {categoryName}
              </div>
            )}

            <div className="grid-view">
              {dataSave.map((item: any, index) => (
                <div
                  key={index}
                  className={`grid-item ${
                    listItemData === item._id ? "grid-item-selected" : ""
                  }`}
                >
                  {listItemData == item._id && secondLoader && (
                    <div
                      className="card"
                      style={{
                        height: "200px",
                        width: "21vw",
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 111,
                      }}
                    >
                      <ClipLoader
                        color={"#fe0e78"}
                        loading={true}
                        size={45}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  )}
                  <div>
                    <div
                      onClick={() => onClickImageVideoPlay(item, index)}
                      style={{ cursor: "pointer" }}
                      className="card"
                    >
                      <img
                        style={{
                          borderRadius: "10px",
                          width: "21vw",
                          objectFit: "cover",
                          height: "200px",
                        }}
                        src={item.photo}
                        // alt="Description of the image"
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        color: "#000",
                        alignSelf: "flex-start",
                        width: "21vw",
                        fontFamily: "Inter-Bold",
                      }}
                    >
                      {item.name}
                    </div>
                    <div
                      style={{
                        width: "21vw",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <b
                        style={{
                          color: "#282345",
                          opacity: "30%",
                          fontSize: "12px",
                          fontFamily: "Roboto-Regular",
                          flex: 4,
                          paddingTop: "5px",
                        }}
                      >
                        Duration: {item.duration}min
                      </b>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <ClipLoader
            color={"#fe0e78"}
            loading={true}
            size={80}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </div>
    );
  };

  const MainListData = () => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          alt=""
          style={{
            height: "70%",
            position: "absolute",
            objectFit: "cover",
            width: "100%",
          }}
          src={require("../src/assets/images/image_header_bg.png")}
        />
        <div
          className="horizontal-scroll-container"
          style={{
            width: "100%",
            transform: `translateX(-${scrollX}px)`,
            display: "flex",
          }}
        >
          <div
            style={{
              width: "100%",
              marginLeft: "150px",
            }}
          >
            <Slider
              ref={sliderRef}
              draggable={false}
              className="my-slider"
              {...settings2}
            >
              {MathsData.map((item: any, index) => (
                <div
                  onClick={() => onClickMainSliderList(item, index)}
                  key={index}
                  style={{ width: "100%" }}
                >
                  <div
                    className={`card-container ${
                      selectedItem === item.name
                        ? "select-color"
                        : "unselect-color"
                    }`}
                  >
                    <div
                      style={{
                        width: "100%",
                        cursor: "pointer",
                      }}
                      className={"cardMaths"}
                    >
                      <img
                        style={{
                          borderRadius: "10px",
                          width: "93%",
                          height: "210px",
                          objectFit: "fill",
                        }}
                        src={item.url}
                        // alt="Description of the image"
                      />
                    </div>
                    <div style={{ width: "100%", height: 10 }} />
                    <div
                      style={{
                        color: "#000",
                        textAlign: "center",
                        width: "100%",
                        fontFamily: "Inter-Bold",
                      }}
                    >
                      {item.name}
                    </div>
                    <div style={{ width: "100%", height: 10 }} />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  };
  const playerRef = useRef<any>(null);
  const modalRefFullScreen = useRef<any>(null);
  const onstartVideo = () => {
    setThirdLoader(false);
    setControlShow(true);
    if (modalRefFullScreen.current) {
      const playerNode = findDOMNode(modalRefFullScreen.current);
      if (playerNode instanceof Element) {
        document.body.style.overflow = "hidden";
        playerNode.requestFullscreen().catch((err: Error) => {});
      }
    }
  };

  const onEndVideo = () => {
    setControlShow(false);
    setCurrentTime(0);
    setDuration(0);
    setVideoUrl("");
    setPopUpVisible(false);
    document.body.style.overflow = "unset";
  };

  const formatTime = (seconds: any) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const handleFastForward = () => {
    if (backwardSelection) {
      setForwardSelection(true);
      setBackwardSelection(false);
    }
    const newTime = currentTime + 5; // Add 5 seconds
    if (isPlaying) {
      playerRef.current.pause();
    }
    playerRef.current.seekTo(newTime, "seconds");
    setCurrentTime(newTime);
    if (isPlaying) {
      playerRef.current.play();
    }
  };
  const handleRewind = () => {
    if (forwardSelection) {
      setForwardSelection(false);
      setBackwardSelection(true);
    }
    if (currentTime > 0) {
      const newTime = currentTime - 5; // Subtract 5 seconds
      if (isPlaying) {
        playerRef.current.pause();
      }
      playerRef.current.seekTo(newTime, "seconds");
      setCurrentTime(newTime);
      if (isPlaying) {
        playerRef.current.play();
      }
    }
  };
  const videoPlay = () => {
    return (
      <Modal
        ref={modalRefFullScreen}
        ariaHideApp={false}
        onRequestClose={() => (
          setVideoUrl(""), setControlShow(false), setPopUpVisible(false)
        )}
        style={{
          overlay: {
            background: "rgba(0, 0, 0, 0.7)",
          },
          content: {
            overflow: "hidden",
            border: "1px solid rgba(0, 0, 0, 0.7)",
            padding: 0,
            margin: 0,
            display: "flex",
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            width: "100%", // Set width to 100%
            height: "100%", // Set height to 100%
            alignItems: "center", // Center content horizontally
            justifyContent: "center", // Center content vertically
          },
        }}
        isOpen={popUpVisible}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            background: "#000",
            height: "100%",
          }}
        >
          {thirdLoader && (
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 111,
              }}
            >
              <ClipLoader
                color={"#fe0e78"}
                loading={true}
                size={45}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
          <ReactPlayer
            ref={playerRef}
            onEnded={onEndVideo}
            onPlay={onstartVideo}
            onError={() => setThirdLoader(false)}
            url={videoUrl}
            playing={true}
            controls={false}
            width="100%"
            height="100%"
            config={{
              file: { forceHLS: publishByBoclipsVideo },
            }}
            onReady={() => setThirdLoader(false)}
            onProgress={(item: any) => setCurrentTime(item.playedSeconds)}
            onDuration={(item: any) => setDuration(item)}
          />
          {controlShow && (
            <div
              style={{
                position: "absolute",
                bottom: 5,
                background: "rgba(0, 0, 0, 0.7)",
                display: "flex",
                alignItems: "center",
                borderRadius: "20px",
                padding: "5px 10px 5px 10px",
                gap: 10,
                justifyContent: "flex-end", // align items to the end of the container
                right: 15,
              }}
            >
              <div
                style={{
                  color: "#fff",
                }}
              >
                {currentTime > 0
                  ? "0" +
                    formatTime(currentTime) +
                    " / 0" +
                    formatTime(duration)
                  : "00:00 / " + formatTime(duration)}
              </div>

              <img
                onClick={handleRewind}
                src={require("../src/assets/images/ic_fast_forward.png")}
                style={{
                  width: "2rem",
                  cursor: "pointer",
                  transform: "rotate(180deg)",
                  border: backwardSelection ? "1px solid red" : "0px solid red",
                  boxShadow: backwardSelection
                    ? "0px 0px 1px 1px red"
                    : "0px 0px 0px 0px pink",
                  objectFit: "contain",
                }}
              />
              <img
                onClick={handleFastForward}
                src={require("../src/assets/images/ic_fast_forward.png")}
                style={{
                  cursor: "pointer",
                  border: forwardSelection ? "1px solid red" : "0px solid red",
                  boxShadow: forwardSelection
                    ? "0px 0px 1px 1px red"
                    : "0px 0px 0px 0px pink",
                  width: "2rem",
                  objectFit: "contain",
                }}
              />
            </div>
          )}
        </div>
      </Modal>
    );
  };

  useEffect(() => {
    setListManage(true);
    setWatchSelection(false);
    setWatchSelectionItem(false);
    getListApiCall(MathsData[0]);
    setCardRightLeftIndex(0);
    setSelectedItem(MathsData[0].name);
    setfirsTimeKeyDown(false);
    sliderRef.current?.slickGoTo(0);
    setManageIfElse(false);
    setSecondManageIfElse(false);
  }, []);

  useEffect(() => {
    if (footerItem == "Home") {
      const screenHeight = window.innerHeight;
      const handleRemoteControl = (ev: any) => {
        console.log("evev", ev);

        switch (ev.keyCode) {
          case 37:
            if (popUpVisible) {
              setForwardSelection(false);
              setBackwardSelection(true);
              return;
            }
            if (manageIfElse) {
              if (firstTimeLeftKeyPress) {
                return;
              }
              if (watchSelection) {
                setWatchSelectionItem(true);
                setfirsTimeKeyDown(true);
                setWatchSelection(true);
                setFirstTimeLeftKeyPress(true);
                return;
              }
            } else {
              if (listManages) {
                if (!loader) {
                  let indexLeft = cardLeftRightIndex - 1;
                  if (indexLeft >= 0) {
                    setSelectedItem(MathsData[indexLeft].name);
                    getListApiCall(MathsData[indexLeft]);
                    setCardRightLeftIndex(indexLeft);
                    setFirstDataSelect(true);
                    sliderRef.current?.slickPrev();
                  } else {
                    setSelectedItem(MathsData[6].name);
                    getListApiCall(MathsData[6]);
                    setCardRightLeftIndex(6);
                    setFirstDataSelect(true);
                    sliderRef.current?.slickPrev();
                  }
                }
              } else {
                let indexSetUp = firstDataSelect ? 0 : 1;
                const newIndexUp = cardDataIndex - indexSetUp;
                if (newIndexUp >= 0) {
                  const filterData = arrayLengthStrore.filter(
                    (item: any) => item == newIndexUp + 1
                  );
                  if (filterData.length == 1) {
                    window.scrollBy(0, -screenHeight / 2.5);
                  }
                  setRemoteData([dataSave[newIndexUp]]);
                  setListDataItem(dataSave[newIndexUp]._id);
                  setCradDataIndex(newIndexUp);
                  setFirstDataSelect(false);
                  setListManage(false);
                }
              }
            }
            break;
          case 39:
            if (popUpVisible) {
              setForwardSelection(true);
              setBackwardSelection(false);
              return;
            }
            setFirstTimeLeftKeyPress(false);
            if (tabSelection == "Home") {
              setFirstDataSelect(true);
              setSelectedItem("");
              setTabSelection("Read");
              setFooterItem("Read");
              setfirsTimeKeyDown(true);
              setWatchSelection(false);
              setManageIfElse(true);
              setSecondManageIfElse(false);
              setFirstTimeLeftKeyPress(false);
              setListManage(true);
              return;
            }

            if (manageIfElse) {
              if (watchSelection) {
                setWatchSelectionItem(true);
                setfirsTimeKeyDown(true);
                setWatchSelection(false);
                setListManage(false);
                setSecondManageIfElse(false);
              }
            } else {
              if (firsTimeKeyDown) {
                setWatchSelection(false);
                setWatchSelectionItem(false);
                getListApiCall(MathsData[0]);
                setCardRightLeftIndex(0);
                setSelectedItem(MathsData[0].name);
                setfirsTimeKeyDown(false);
                setListManage(true);
              } else if (listManages) {
                if (!loader) {
                  let indexSeRight = cardLeftRightIndex + 1;
                  if (indexSeRight < MathsData.length) {
                    setSelectedItem(MathsData[indexSeRight].name);
                    getListApiCall(MathsData[indexSeRight]);
                    setCardRightLeftIndex(indexSeRight);
                    setFirstDataSelect(true);
                    sliderRef.current?.slickNext();
                  } else {
                    setSelectedItem(MathsData[0].name);
                    getListApiCall(MathsData[0]);
                    setCardRightLeftIndex(0);
                    setFirstDataSelect(true);
                    sliderRef.current?.slickNext();
                  }
                }
              } else {
                let indexSetUp = firstDataSelect ? 0 : 1;
                const newIndexUp = cardDataIndex + indexSetUp;
                if (newIndexUp < dataSave.length) {
                  const filterData = arrayLengthStrore.filter(
                    (item: any) => item == newIndexUp
                  );
                  if (filterData.length == 1) {
                    window.scrollBy(0, screenHeight / 2.5);
                  }
                  setRemoteData([dataSave[newIndexUp]]);
                  setListDataItem(dataSave[newIndexUp]._id);
                  setCradDataIndex(newIndexUp);
                  setFirstDataSelect(false);
                  setListManage(false);
                }
              }
            }
            break;
          case 38:
            if (popUpVisible) {
              return;
            }
            window.scrollBy(0, -screenHeight / 2.5);
            let indexSetUp = firstDataSelect ? 0 : 4;
            const newIndexUp = cardDataIndex - indexSetUp;
            setFooterRow(false);
            if (manageIfElse) {
            } else {
              if (!listManages) {
                if (newIndexUp >= 0) {
                  if (dataSave.length == 0) {
                    let nameGet = MathsData.filter(
                      (e) => e.Apiname == categoryName
                    );
                    setSelectedItem(nameGet[0].name);
                    setRemoteData([]);
                    setListManage(true);
                    setListDataItem("");
                    setCradDataIndex(0);
                    setFirstDataSelect(true);
                    setSecondManageIfElse(false);
                    setManageIfElse(false);
                    window.scrollBy(0, screenHeight / 2.5);
                  } else {
                    setRemoteData([dataSave[newIndexUp]]);
                    setListDataItem(dataSave[newIndexUp]._id);
                    setCradDataIndex(newIndexUp);
                    setFirstDataSelect(false);
                    setListManage(false);
                  }
                } else {
                  let nameGet = MathsData.filter(
                    (e) => e.Apiname == categoryName
                  );
                  setSelectedItem(nameGet[0].name);
                  setRemoteData([]);
                  setListManage(true);
                  setListDataItem("");
                  setCradDataIndex(0);
                  setFirstDataSelect(true);
                  setSecondManageIfElse(false);
                  setManageIfElse(false);
                }
              } else if (listManages) {
                setFirstDataSelect(true);
                setSelectedItem("");
                setTabSelection("Home");
                setfirsTimeKeyDown(true);
                setWatchSelection(false);
                setManageIfElse(true);
                setSecondManageIfElse(false);
                setFirstTimeLeftKeyPress(false);
                setListManage(true);
              }
            }

            break;
          case 40: // VK_DOWN
            if (popUpVisible) {
              return;
            }
            setTabSelection("");
            if (secondManageIfElse) {
              if (manageIfElse) {
                if (watchSelection) {
                  setWatchSelectionItem(true);
                  setfirsTimeKeyDown(true);
                  setWatchSelection(false);
                  setFirstTimeLeftKeyPress(true);
                  setListManage(false);
                  setSecondManageIfElse(false);
                }
              }
            } else {
              if (!loader) {
                setManageIfElse(false);
                if (firsTimeKeyDown) {
                  setListManage(true);
                  setWatchSelection(false);
                  setWatchSelectionItem(false);
                  getListApiCall(MathsData[0]);
                  setCardRightLeftIndex(0);
                  setSelectedItem(MathsData[0].name);
                  setfirsTimeKeyDown(false);
                  sliderRef.current?.slickGoTo(0);
                } else {
                  window.scrollBy(0, screenHeight / 2.5);
                  setListManage(false);
                  let indexSet = firstDataSelect ? 0 : 4;
                  const newIndex = cardDataIndex + indexSet;
                  if (newIndex < dataSave.length) {
                    if (dataSave.length != 0) {
                      setRemoteData([dataSave[newIndex]]);
                      setListDataItem(dataSave[newIndex]._id);
                      setCradDataIndex(newIndex);
                      setFirstDataSelect(false);
                      setSelectedItem("");
                    } else {
                      setFooterRow(true);
                      setFooterItem("Home");
                      setListDataItem("");
                      setCradDataIndex(-2);
                    }
                  }
                }
              }
            }

            break;
          case 13: // VK_ENTER
            if (popUpVisible) {
              if (forwardSelection) {
                handleFastForward();
              } else {
                handleRewind();
              }
              return;
            }

            if (footerRow) {
              if (footerItem == "Home") {
                window.scrollTo({ top: 0, behavior: "smooth" });
                clearData();
              } else {
                window.scrollTo({ top: 0 });
                setTimeout(() => {
                  window.location.href = "https://ambanilearn.com/";
                }, 500);
              }
            } else {
              if (remoteData.length > 0) {
                byBoclipsVideoUrl(remoteData[0]);
                setCurrentTime(0);
                setDuration(0);
              }
              if (watchSelectionItem) {
                localStorage.removeItem("isLoggedIn");
                localStorage.removeItem("token");
                history.push("/Login");
              }
            }

            break;

          case 27:
            setVideoUrl("");
            setPopUpVisible(false);
            setControlShow(false);
            document.body.style.overflow = "unset";
            break;

          case 8:
            setVideoUrl("");
            setPopUpVisible(false);
            setControlShow(false);
            document.body.style.overflow = "unset";
            break;
          default:
            break;
        }
        ev.preventDefault();
      };

      document.addEventListener("keydown", handleRemoteControl);

      return () => {
        document.removeEventListener("keydown", handleRemoteControl);
      };
    }
  }, [
    dataSave,
    cardDataIndex,
    firstDataSelect,
    popUpVisible,
    selectedItem,
    watchSelectionItem,
    watchSelection,
    firstTimeLeftKeyPress,
    manageIfElse,
    secondManageIfElse,
    footerItem,
    footerRow,
    initialSlide,
    tabSelection,
    currentTime,
    forwardSelection,
    backwardSelection,
  ]);

  useEffect(() => {
    if (popUpVisible == false) {
      document.body.style.overflow = "unset";
    }
  }, [popUpVisible]);

  const byBoclipsVideoUrl = async (item: any) => {
    // setPublishByBoclips(item.publishByBoclips);
    setCurrentTime(0);
    setDuration(0);
    // setVideoUrl(item.watchvideo);
    // setThirdLoader(true);
    // setPopUpVisible(true);

    setPublishByBoclips(item.publishByBoclips);

    if (item.publishByBoclips == true) {
      setSecondLoader(true);
      const token = localStorage.getItem("token");
      // const headers = {
      //   Authorization: `Bearer ${token}`,
      // };


      const baseUrl =
        "https://api.ambanilearn.com/api/v1/hwatch/boclipsForTv/getVideoUrl/" + item.boclipsId;
      const url = `${baseUrl}?token=Bearer ${token}`;

      axios
        .get(
          url
          // `https://api.ambanilearn.com/api/v1/hwatch/boclips/getVideoUrl/${item.boclipsId}`,
          // { headers }
        )
        .then((response) => {
          console.log("respdcddonse", response);

          setSecondLoader(false);
          setVideoUrl(response.data.data);
          setThirdLoader(true);
          setPopUpVisible(true);
        })
        .catch((error) => {
          setSecondLoader(false);
          console.error("Error fetching data:", error);
        });
    } else {
      setVideoUrl(item.watchvideo);
      setThirdLoader(true);
      setPopUpVisible(true);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      switch (event.key) {
        case "ArrowUp":
          break;
        case "ArrowDown":
          break;

        case "ArrowLeft":
          if (tabSelection == "Read") {
            setTabSelection("Home");
            setFooterItem("Home");
            setFirstDataSelect(true);
            setSelectedItem("");
            setfirsTimeKeyDown(true);
            setManageIfElse(true);
            setSecondManageIfElse(false);
            setFirstTimeLeftKeyPress(false);
            setListManage(true);
          }
          break;
        case "ArrowRight":
          break;
        case "Enter":
          break;
        default:
          break;
      }
    };
    if (footerItem == "Read") {
      window.addEventListener("keydown", handleKeyPress);
    }
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [tabSelection]);

  const logOutButtonSelect = (item: any) => {
    setFirstDataSelect(true);
    setSelectedItem("");
    setWatchSelectionItem(true);
    setfirsTimeKeyDown(true);
    setTabSelection("");
    setWatchSelection(false);
    setManageIfElse(true);
    setSecondManageIfElse(false);
    setFirstTimeLeftKeyPress(false);
    setListManage(true);
  };

  const leftButtonLogountButtonSelect = (item: any) => {
    if (watchSelectionItem == false) {
      setTabSelection("Read");
      setFooterItem("Read");
      setWatchSelectionItem(false);
    }
  };

  const onGetSelectionReadIndex = (item: any) => {
    if (item) {
      setTabSelection("Read");
      setWatchSelectionItem(false);
    } else {
      setListManage(true);
      setWatchSelection(false);
      setWatchSelectionItem(false);
      setfirsTimeKeyDown(false);
      setManageIfElse(false);
      setSecondManageIfElse(false);
    }
  };

  const settings2 = {
    slidesToShow: 5,
    infinite: true,
    prevArrow: undefined,
    nextArrow: undefined,
    slidesToScroll: 1,
    initialSlide: initialSlide,
  };

  const clearData = () => {
    setSelectedItem("");
    setListDataItem("");
    setFirstDataSelect(true);
    setListManage(true);
    setfirsTimeKeyDown(true);
    setWatchSelection(true);
    setManageIfElse(true);
    setSecondManageIfElse(true);
    setFirstTimeLeftKeyPress(true);
    setFooterItem("Home");
    setCradDataIndex(0);
    setCardRightLeftIndex(0);
  };
  const containerRef = useRef<HTMLDivElement>(null);

  const onClickScrollOnTop = () => {
    const screenHeight = window.innerHeight;
    window.scrollBy(0, -screenHeight / 2.5);
  };
  const onClickScrollOnBottom = () => {
    const screenHeight = window.innerHeight;
    window.scrollBy(0, screenHeight / 2.5);
  };

  return (
    <div ref={containerRef} style={{ width: "100%" }}>
      {popUpVisible && videoPlay()}

      <div style={{}}>
        <div
          style={{
            height: footerItem == "Read" ? 60 : 200,
            paddingTop: "10px",
            display: "flex",
            flex: 1,
            justifyContent: "center",
          }}
        >
          <div
            style={{
              alignItems: "center",
              position: "absolute",
              width: "97%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt=""
                style={{
                  objectFit: "contain",
                  width: "10%",
                }}
                src={require("../src/assets/images/ic_ambani_logo.png")}
              />
              <div
                // id="hometab"
                onClick={() => {
                  setTabSelection("Home");
                  setFooterItem("Home");
                  setFirstDataSelect(true);
                  setSelectedItem("");
                  setfirsTimeKeyDown(true);
                  setManageIfElse(true);
                  setSecondManageIfElse(false);
                  setFirstTimeLeftKeyPress(false);
                  setListManage(true);
                }}
                style={{
                  cursor: "pointer",
                  borderRadius: "7px",
                  padding: "5px",
                  border:
                    tabSelection == "Home" ? "1px solid pink" : "0px solid red",
                  color: footerItem == "Home" ? "#FE0D78" : "#000",
                  boxShadow:
                    tabSelection == "Home"
                      ? "1px 1px 20px 1px red"
                      : "0px 0px 0px 0px pink",
                }}
                className="footerText"
              >
                Home
              </div>
              <div
                onClick={() => {
                  setFirstDataSelect(true);
                  setSelectedItem("");
                  setTabSelection("Read");
                  setFooterItem("Read");
                  setfirsTimeKeyDown(true);
                  setWatchSelection(false);
                  setManageIfElse(true);
                  setSecondManageIfElse(false);
                  setFirstTimeLeftKeyPress(false);
                  setListManage(true);
                }}
                style={{
                  cursor: "pointer",
                  borderRadius: "7px",
                  padding: "5px",
                  border:
                    tabSelection == "Read" ? "1px solid pink" : "0px solid red",
                  color: footerItem == "Read" ? "#FE0D78" : "#000",
                  boxShadow:
                    tabSelection == "Read"
                      ? "1px 1px 20px 1px red"
                      : "0px 0px 0px 0px pink",
                }}
                className="footerText"
              >
                Read
              </div>
            </div>

            <button
              onClick={() => {
                localStorage.removeItem("isLoggedIn");
                localStorage.removeItem("token");
                history.push("/Login");
              }}
              id="logout"
              style={{
                zIndex: popUpVisible ? -1 : 1,
                cursor: "pointer",
                height: "38px",
                marginTop: "10px",
                border: watchSelectionItem ? "1px solid pink" : "0px solid red",
                boxShadow: watchSelectionItem
                  ? "1px 1px 20px 1px red"
                  : "0px 0px 0px 0px pink",
              }}
            >
              Logout
            </button>
          </div>
        </div>
      </div>

      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          marginTop: "1vh",
        }}
      >
        {footerItem == "Home" && (
          <div className="mainDiv">{MainListData()}</div>
        )}
        {footerItem == "Home" && <div className="mainDiv2">{FreeDaTa()}</div>}
        {footerItem === "Read" && (
          <Read
            tabSelection={tabSelection}
            setTabSelection={() => setTabSelection("")}
            logOutButtonSelect={(item: any) => logOutButtonSelect(item)}
            leftButtonLogountButtonSelect={(item: any) =>
              leftButtonLogountButtonSelect(item)
            }
            setLoaderRead={(value: any) => setLoader(value)}
            getReadSelectionId={(item: any) => onGetSelectionReadIndex(item)}
            logOutSelected={watchSelectionItem}
          />
        )}

        {footerItem == "Home" && (
          <div className="mainDiv2">
            <article className="article">
              <img
                style={{
                  width: "100%",
                  objectFit: "cover",
                }}
                alt=""
                src={require("../src/assets/footer_image.png")}
              ></img>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "14vw",
                }}
                className="header"
              ></div>
            </article>
          </div>
        )}
      </div>

      {!loader && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: "rgba(0, 0, 0, 0.7)",
            position: "fixed",
            top: "85%",
            // gap: "10px",
            borderRadius: "10px",
            right: "2%",
            padding: "5px 7px 5px 7px",
          }}
        >
          <img
            alt=""
            onClick={() => onClickScrollOnTop()}
            style={{
              cursor: "pointer",
              height: "1.7rem",
              width: "1.7rem",
              transform: "rotate(180deg)",
            }}
            src={require("../src/assets/images/ic_down_arrow.png")}
          />
          <img
            alt=""
            onClick={() => onClickScrollOnBottom()}
            style={{
              marginTop: "1.2rem",
              cursor: "pointer",
              height: "1.7rem",
              width: "1.7rem",
            }}
            src={require("../src/assets/images/ic_down_arrow.png")}
          />
        </div>
      )}
    </div>
  );
};

export default Home;
